import React from "react";

const error404 = () => {
  return (
    <div>
      <h1>Ups esta pagina no existe</h1>
    </div>
  );
};

export default error404;
